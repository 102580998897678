<template>
  <div
    class="chart-container"
    :style="dynamicSize ? { height: '100%' } : {}"
    ref="container"
  >
    <div v-if="busy" class="loading">
      <div>
        <i class="fa fa-refresh fa-spin"></i>
      </div>
    </div>
    <img
      v-if="generateImage"
      class="chart"
      ref="chartImage"
      :src="''"
      :style="{ display: $store.getters.print ? 'block' : 'none' }"
    />
    <div
      class="chart html2canvas-ignore"
      data-html2canvas-ignore
      ref="chart"
      style="height: inherit"
    ></div>
  </div>
</template>
<script>
import echarts from "echarts";
import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
import "@/utils/echarts-themes/telemetria";

import { cloneDeep, isEqual, debounce } from "lodash";
const ResizeObserver = window.ResizeObserver || Polyfill;

const serieFilter = ($vm, serie) => {
  if (serie?.validation) {
    serie.data = serie.data.filter(
      (sample) =>
        sample.value.length > 1 &&
        $vm.$utils.isTrue(serie.validation, {
          $value: sample.value[1]
        })
    );
  }
};

const serieEvaluation = ($vm, serie) => {
  if (serie.itemStyle.expression) {
    serie.data.forEach((sample) => {
      if (!sample.value.length > 1) return;
      sample.initial = sample.value[1] || 0;
      sample.value[1] = $vm.$utils.eval(serie.itemStyle.expression, {
        $value: sample.initial
      });
    });
  }
};

const serieConfig = ($vm, serie) => {
  if (serie.lineStyle?.waveForm) {
    switch (serie.lineStyle.waveForm) {
      case "square":
        serie.step = "end";
        serie.smooth = false;
        break;
      case "triangle":
        serie.step = false;
        serie.smooth = false;
        break;
      case "sin":
        serie.step = false;
        serie.smooth = true;
        break;
    }
  }
};

export { serieFilter, serieEvaluation, serieConfig };

export default {
  name: "EquipmentHistoryChartDisplay",
  data: function() {
    return {
      busy: true,
      isOneDay: false,
      passedMidnight: false
    };
  },
  props: {
    dataset: {
      type: Array,
      required: false,
      default: null
    },
    widgetOptions: {
      type: Object,
      required: false,
      default: function() {
        return null;
      }
    },
    generateImage: {
      type: Boolean,
      required: false,
      default: false
    },
    dynamicSize: {
      type: Boolean,
      default: false
    },
    svg: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    dataset: {
      handler(n, o) {
        if (isEqual(n, o)) return;
        this._updateChart();
      },
      deep: true,
      immediate: true
    },
    widgetOptions: {
      handler(n, o) {
        if (isEqual(n, o)) return;
        this._updateChart();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    yScale() {
      return this?.widgetOptions?.yAxis?.yScale || {};
    },
    yScaleType() {
      return this?.yScale?.type || "value";
    },
    textList() {
      if (this.yScaleType != "text_list") return null;
      let items = this?.yScale?.textList?.items || [];
      return items.sort((a, b) => a.state - b.state);
    }
  },
  methods: {
    xAxleFormatter(value, index) {
      if (this.isOneDay) {
        // if current interval is up to one day
        let format = "LT"; // change format to hour and minute
        if (
          // if it's the first or the hour is from the next day
          index == 0 ||
          (moment(value).hours() == 0 && !this.passedMidnight)
        ) {
          format += " (l)"; // add the date to format
          // date in midnight already setted
          if (index != 0) this.passedMidnight = true;
        }
        // resets "midnight setted" when another formatting cycle begins
        if (index == 0 && this.passedMidnight) this.passedMidnight = false;
        return moment(value).format(format);
      } else return moment(value).format("L");
    },
    // yAxisFormatter(value) {
    //   return value.toLocaleString();
    // },
    yAxisFormatter(value) {
      if (this.yScaleType == "text_list") {
        if (this?.textList?.length) {
          let state = this.textList.find(({ state }) => state == value);
          return (state && state?.label) || "";
        }
      } else if (this.yScaleType == "expression") {
        /*
        var exp = `
          value >= 0 && value <= 1
            ? {
                "0": "Off",
                "1": "on"
              }[value]
            : ""
        `;
        */
        if (this?.yScale?.expression) {
          var entry = {
            minimum: this.yAxleProp("min"),
            maximum: this.yAxleProp("max"),
            interval: this.yAxleProp("interval"),
            value: value,
            $value: value
          };
          // var exp =
          //   this.yScale.expression.indexOf("${") >= 0
          //     ? this?.yScale?.expression
          //     : `\$\{${this?.yScale?.expression}\}`;
          return this.$utils.evaluate(entry, this.yScale.expression);
        }
      }
      return value.toLocaleString();
    },
    yAxleProp(name) {
      let yAxis = this?.widgetOptions?.yAxis || {};
      return name in yAxis ? yAxis[name] : "auto";
    },
    formatedDataValue(dataId, value) {
      let data = (this.$store.getters["dashboard/dataList"] || []).find(
        (i) => i.id == dataId
      );
      if (data && data?.value_format_type?.id) {
        let format = this.$root.config.references.data_value_format_types.find(
          ({ id }) => id == data?.value_format_type?.id || ""
        );
        if (format.format_mask == "text_list") {
          return value;
        } else if (
          format.format_mask == "duration" &&
          data.custom_format.indexOf("%") == -1
        ) {
          return moment
            .duration(parseInt(value), data.unity_label)
            .format(data.custom_format || undefined, { trim: false });
        } else if (format.format_mask == "custom") {
          return this.$utils.sprintf(data.custom_format, parseFloat(value));
        } else {

          var fmt = data.unity_label ? `${format.format_mask} ${data.unity_label}` : format.format_mask;
          return this.$utils.sprintf(fmt, parseFloat(value));

        }
      }
      return value;
    },
    tooltipFormatter(params) {
      let self = this;
      this.prvTip = this.prvTip || {}
      // let content = moment(params[0].name).format("LTS - ll");
      let content = "";
      let found = {};
      params.forEach((series) => {
        let value = "";
        let initial = ""; //
        if (series?.data?.data_id) {
          value = this.formatedDataValue(
            series?.data?.data_id,
            series.value[1]
          );
          if ("initial" in (series?.data || {})) {
            initial = this.formatedDataValue(
              series?.data?.data_id,
              series?.data.initial || 0
            );
          }
        } else {
          value =
            (series?.data?.format &&
              ["text_list", "duration", "custom"].indexOf(series.data.format) ==
              -1) ||
              ""
              ? self.$utils.sprintf(series.data.format, series.value[1])
              : series.value[1];
        }
        if (!content) {
          content += this.fmtDateTime(series.value[0]);
        }
        var serieName =
          series.seriesName.slice(0, 30) +
          (series.seriesName.length > 30 ? "..." : "");
        var fmtValue = `${value}${initial === "" ? "" : " (" + initial + ")"}`;
        content += `
            <br>
            <div style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${series.color};"></div>
            ${serieName}: ${fmtValue}
          `;
        if (series?.data?.data_id) {
          found[series?.data?.data_id] = true;
          this.prvTip[series?.data?.data_id] = {
            color: series.color,
            name: serieName,
            value: fmtValue
          };
        }
      });
      for (var data_id in this.prvTip) {
        if (!found[data_id]) {
          var entry = this.prvTip[data_id];
          content += `
            <br>
            <div style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${entry.color};"></div>
            ${entry.name}: ${entry.value}
          `;

        }
      }
      return content;
    },
    fmtDateTime: function(value) {
      return value
        ? this.$dt.format(value) || moment(value).format("LTS - ll")
        : value;
    },
    sliderFormatter(value) {
      if (this.isOneDay) return moment(value).format("LTS");
      else return moment(value).format("L");
    },
    insideFormatter(value) {
      if (this.isOneDay) return moment(value).format("LTS");
      else return moment(value).format("L");
    },
    createChart() {
      const small = window.innerWidth < 768;
      const printPreview = this.$store.getters.print;
      let hasData = false;
      let series = JSON.parse(JSON.stringify(this.dataset || []));
      this.updateContainerHeight();
      let maxPoints = 100; // max points displaying at once
      let names = {};
      series.forEach((serie) => {
        names[serie.name] = (names[serie.name] ?? -1) + 1;
      });
      series.forEach((serie, ix) => {
        if (!serie?.data?.length) return;
        hasData = true;
        if (names[serie.name] > 0) {
          serie.name = `[${ix}] ${serie.name}`;
        }
        serieConfig(this, serie);
        if (
          serie?.validationReadValue == undefined ||
          serie?.validationReadValue
        ) {
          // filter raw value first, then calculate the new value
          serieFilter(this, serie);
          if (serie?.itemStyle?.expressionSlot?.chart) {
            serieEvaluation(this, serie);
          }
        } else {
          // calculate the new value, and filter the calculated value
          if (serie?.itemStyle?.expressionSlot?.chart) {
            serieEvaluation(this, serie);
          }
          serieFilter(this, serie);
        }
        if (serie.animation && (printPreview || small)) {
          serie.animation = false;
        }
      });

      // find series with bigger data length
      let maxLength = series.reduce(
        (max, { data }) => (data?.length > max ? data.length : max),
        0
      ),
        biggerThanMax = false,
        // biggerThanMax = maxLength > maxPoints,
        percentange = (maxPoints / maxLength) * 100;

      let start = biggerThanMax ? 50 - percentange / 2 : 0;
      let end = biggerThanMax ? 50 + percentange / 2 : 100;
      // let start = biggerThanMax ? 50 - percentange / 2 : 25;
      // let end = biggerThanMax ? 50 + percentange / 2 : 100;

      // specify chart configuration item and data

      let chartOptions = cloneDeep(this.widgetOptions) || {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false
          },
          formatter: null
        },
        legend: {
          type: "scroll"
        },
        toolbox: {
          show: !small && !printPreview && hasData,
          orient: "vertical",
          top: 30,
          feature: {
            restore: {},
            dataZoom: {
              title: {
                zoom: this.$t("area_zoom"),
                back: this.$t("back")
              },
              emphasis: {
                iconStyle: {
                  textFill: "#fff",
                  textBackgroundColor: "rgba(50,50,50,0.7)",
                  textPadding: 5,
                  textPosition: "left"
                }
              }
            }
          }
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: null
          }
        },
        yAxis: {
          axisLabel: {
            formatter: null
          }
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: small ? "5%" : "15%",
          top: "10%",
          containLabel: true
        },
        dataZoom: [
          {
            show: !small && !printPreview && hasData,
            type: "slider",
            labelFormatter: null,
            start,
            end
          },
          {
            show: !small && !printPreview && hasData,
            type: "inside",
            start,
            end
          }
        ],
        series: {
          type: "line",
          data: []
        }
      };

      // tooltip formatter
      if (
        "formatter" in chartOptions?.tooltip &&
        !chartOptions.tooltip.formatter
      ) {
        chartOptions.tooltip.formatter = this.tooltipFormatter;
      }
      // x Axle formatter
      if (
        "formatter" in chartOptions?.xAxis?.axisLabel &&
        !chartOptions.xAxis.axisLabel.formatter
      ) {
        chartOptions.xAxis.axisLabel = chartOptions.xAxis.axisLabel || {};
        chartOptions.xAxis.axisLabel.formatter = this.xAxleFormatter;
      }
      // y axis formatter
      if (chartOptions?.yAxis) {
        // backward compatibility issues: remove any "auto" column
        ["min", "max", "interval"].forEach((p) => {
          if ((chartOptions.yAxis || {})[p] == "auto") {
            delete chartOptions.yAxis[p];
          }
        });

        if (!chartOptions?.yAxis?.axisLabel?.formatter) {
          chartOptions.yAxis.axisLabel = chartOptions.yAxis.axisLabel || {};
          chartOptions.yAxis.axisLabel.formatter = this.yAxisFormatter;
        }
      }
      // zoom formatters
      if (chartOptions?.dataZoom?.length) {
        let slider = chartOptions.dataZoom.find((i) => i.type == "slider");
        if (slider) {
          if (slider.show && (printPreview || !hasData)) {
            slider.show = false;
          }
          if ("labelFormatter" in slider && !slider.labelFormatter) {
            slider.labelFormatter = this.sliderFormatter;
          }
          if (!slider.filterMode) {
            slider.filterMode = "none";
          }
        }
        let inside = chartOptions.dataZoom.find((i) => i.type == "inside");
        if (inside) {
          if (inside.show && printPreview) {
            inside.show = false;
          }
          if ("labelFormatter" in inside && !inside.labelFormatter) {
            inside.labelFormatter = this.sliderFormatter;
          }
          if (!inside.filterMode) {
            inside.filterMode = "none";
          }
        }
      }
      // toolbox
      if (chartOptions?.toolbox) {
        if (chartOptions.toolbox.show && (printPreview || !hasData)) {
          chartOptions.toolbox.show = false;
        }
      }
      // tooltip
      if (chartOptions?.tooltip) {
        if (
          chartOptions?.tooltip?.axisPointer?.animation &&
          (printPreview || !hasData)
        ) {
          chartOptions.tooltip.axisPointer.animation = false;
        }
      }
      // legend
      if (chartOptions?.legend) {
        if (chartOptions?.legend?.show && !hasData) {
          chartOptions.legend.show = false;
        }
      }

      chartOptions.series = series;
      chartOptions.height = "auto";
      this.initOneDay(series);
      let el = this.$refs?.chart || null;
      if (el) {
        let prv = this.getChart();
        if (prv) {
          prv.clear();
        }
        var chart = echarts.init(el, null, {
          renderer: this.svg ? "svg" : "canvas"
        });
        if (this.generateImage) {
          chart.on("finished", this.onFinished);
        }
        chart.setOption(chartOptions);
        let self = this;
        chart.on('legendselectchanged', function (params) {
          if (!self.prvTip) return;
          for(var p in ((params?.selected||{}))){
            if (!params.selected[p]){
              for(var d in self.prvTip||{}){
                if (self.prvTip[d]?.name==p){
                  delete self.prvTip[d];
                  break;
                }
              }
            }
          }
        });
      }
      this.busy = false;
    },
    initOneDay(series) {
      if (!(series || []).length) return;
      var iDt = new Date().getTime() + 1;
      var eDt = 0;
      series
        .filter((s) => s.type == "line" || s.type == "bar")
        .forEach(({ data }) => {
          if (data.length) {
            var dt = new Date(data[0].name).getTime();
            if (dt <= iDt) iDt = dt;
            dt = new Date(data[data.length - 1].name).getTime();
            if (dt >= eDt) eDt = dt;
          }
        });
      this.calcOneDay(iDt, eDt);
    },
    calcOneDay(start, end) {
      let diff = moment(end).diff(start, "hours");
      if (diff <= 24) {
        this.isOneDay = true;
      } else {
        this.isOneDay = false;
      }
    },
    onDataZoom(event) {
      // check if event was fired by slider data zoom
      let start, end;
      if (event.dataZoomId) {
        ({ start, end } = getStartAndEndFrom.call(
          this,
          event.start,
          event.end
        ));
      } else if (event.batch[0].dataZoomId.includes("series")) {
        // if it's from inside data zoom
        ({ start, end } = getStartAndEndFrom.call(
          this,
          event.batch[0].start,
          event.batch[0].end
        ));
      } else {
        // if it's from area data zoom
        start = event.batch[0].startValue;
        end = event.batch[0].endValue;
      }

      // let diff = moment(end).diff(start, "hours");
      // if (diff <= 24) {
      //   this.isOneDay = true;
      // } else {
      //   this.isOneDay = false;
      // }
      this.calcOneDay(start, end);

      // convert start and end from percentage to absolute date value
      function getStartAndEndFrom(percentageStart, percentageEnd) {
        let firstDate = moment(
          this.dataset[0].data[this.dataset[0].data.length - 1].value[0]
        );
        let lastDate = moment(this.dataset[0].data[0].value[0]);
        let duration = moment.duration(lastDate.diff(firstDate));

        let start = firstDate
          .clone()
          .add(duration.asMilliseconds() * (percentageStart / 100), "ms");
        let end = firstDate
          .clone()
          .add(duration.asMilliseconds() * (percentageEnd / 100), "ms");
        return { start, end };
      }
    },
    getChart() {
      let el = this.$refs?.chart || null;
      return el ? echarts.getInstanceByDom(el) : null;
    },
    onFinished() {
      if (
        !(this.$refs?.container?.getBoundingClientRect()?.height || 0) ||
        !this.$refs.chart ||
        !this.$refs.chartImage
      ) {
        return;
      }
      const chart = this.getChart();
      let canvas = this.$refs.chart.getElementsByTagName("canvas")[0];
      if (chart) {
        this.$refs.chartImage.src = chart.getDataURL({
          backgroundColor: "#fff",
          type: "jpeg" // Important since png is throwing error while printing
        });
        if (this.$store.getters.print) {
          canvas.style.display = "none";
        } else {
          canvas.style.display = "block";
        }
      } else {
        this.$refs.chartImage.src = "";
        canvas.style.display = "block";
      }
    },
    updateContainerHeight() {
      if (this.$refs.container && !this.dynamicSize) {
        this.$refs.container.style.height =
          this.$parent.$el.clientHeight -
          (this.$refs.container.getBoundingClientRect().top -
            this.$parent.$el.getBoundingClientRect().top) +
          "px";
      }
    },
    panelChangeMonitor() {
      if (this._SizeAnalizer) return;

      this._height = 0; // not reactive
      this._width = 0; // not reactive
      this._skip = false; // not reactive

      this._SizeAnalizer = debounce((els) => {
        if (this.busy || this._skip) return;
        this._skip = true; // not reactive
        // let el = els && els.length ? els[0] : null;
        // let rc = el && el?.target?.getBoundingClientRect();
        let rc =
          (this.$parent && this.$parent.$el.getBoundingClientRect()) || null;
        let h = parseInt((rc && rc?.height) || 0);
        let w = parseInt((rc && rc?.width) || 0);
        if (h != this._height || w != this._width) {
          this._height = h;
          this._width = w;
          if (this._height && this._width) {
            let chart = this.getChart();
            if (chart) {
              this.updateContainerHeight();
              this.$nextTick(() => {
                chart.resize();
                this._skip = false;
              });
              return;
            }
          }
        }
        this._skip = false;
      }, 200);

      const sizeWatcher = new ResizeObserver(this._SizeAnalizer);
      let _p = this.$refs.container;
      if (this.dynamicSize) {
        _p = _p.parentElement;
      } else {
        while (!_p.classList.contains("inner-panel")) {
          _p = _p.parentElement;
          if (!_p) break;
        }
      }
      if (_p) {
        sizeWatcher.observe(_p);
      }
    }
  },
  activated() {
    let chart = this.getChart();
    if (chart) {
      this.updateContainerHeight();
      chart.resize();
    }
  },
  mounted() {
    this.panelChangeMonitor();
    this._updateChart();
  },
  beforeCreate() {
    this._updateChart = debounce(() => {
      if (this.$refs.container) {
        this.createChart();
      }
    }, 500);
  }
};
</script>

<style scoped>
div.chart-container {
  width: 100%;
  height: inherit;
}

.echarts {
  margin: 0;
  width: 100%;
  height: inherit;
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  align-content: center;
  height: inherit;
  font-size: 20pt;
}

.loading > div {
  flex: 1 1 inherit;
  align-self: center;
}

@media screen {
  .no-screen {
    display: none;
  }
}
@media print {
  .no-print {
    display: none;
  }
}
</style>
